<template>
  <div id="ul-1" class="mt-3">
    <b-card title="" v-if="candidate.candidateComments.length>0">
      <app-timeline>
        <app-timeline-item
          v-for="item in candidate.candidateComments"
          :key="item.id"
        >
          <div
            class="
              d-flex
              flex-sm-row flex-column flex-wrap
              justify-content-between
              mb-1 mb-sm-0
            "
          >
            <h6>{{ item.title }}</h6>
            <small class="text-muted">{{ dateFormat(item.createdOn) }}</small>
          </div>
          <p>{{ item.comment }}</p>
          <div
            class="
              d-flex
              flex-sm-row flex-column flex-wrap
              justify-content-between
              mb-1 mb-sm-0
            "
          >
            <b-media>
              <template #aside>
                <b-avatar :src="item.createdByUserAvatarUrl" />
              </template>
              <h6>{{ item.createdByUserFullName }}</h6>
            </b-media>
            <div
              v-if="
                user.role === 'Administrator' ||
                item.createdByUserFullName === user.fullName
              "
              class="d-flex flex-wrap"
            >
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-primary"
                class="btn-icon ml-0"
                @click="editComment(item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-secondary"
                class="btn-icon ml-0"
                @click="deleteComment(item)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>
          </div>
        </app-timeline-item>
      </app-timeline>
    </b-card>
    <b-card title="">
      <b-form class="mt-1" @submit="onSubmit">
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <b-form-group label="Title" label-for="Title">
              <b-form-input id="Title" v-model="title" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <b-form-group label="Comment" label-for="comment">
              <b-form-textarea
                rows="3"
                max-rows="6"
                placeholder="Enter something..."
                id="comment"
                v-model="comment"
                :state="characters > 0"
                :counter="1000"
                :rules="[(v) => (v || '').length <= 1000]"
                @input="textLimit"
              />
            </b-form-group>
            <small>Max Limit {{ characters }} characters</small>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <b-button
              variant="primary"
              class="mt-2 mb-sm-0 mr-0 mr-sm-1"
              type="submit"
            >
              {{ !isUpdate ? "Add" : "Update" }}
            </b-button>
            <b-button
              variant="outline-secondary"
              class="mt-2 mb-sm-0 mr-0 mr-sm-1"
              @click="CancelComment"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BImg,
  BMedia,
  BAvatar,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import router from "@/router";
import moment from "moment";
import { Vue } from "vue-property-decorator";

export default {
  components: {
    BCard,
    BImg,
    BMedia,
    BAvatar,
    AppTimeline,
    AppTimelineItem,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      id: "",
      title: "",
      comment: "",
      characters: 1000,
      isUpdate: false,
      user: JSON.parse(localStorage.getItem("userData")),
    };
  },
  methods: {
    fetchCandidate() {
      store
        .dispatch("candidate/fetchUser", { id: router.currentRoute.params.id })
        .then((response) => {
          this.userData.value = store.state.candidate.candidate;
        })
        .catch((error) => {
          for (const [key, value] of Object.entries(
            error.response.data.errors
          )) {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: "error",
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: value,
              },
            });
          }
        });
    },

    onSubmit(event) {
      event.preventDefault();
      const blankData = {
        title: this.title,
        comment: this.comment,
      };

      if (!this.isUpdate) {
        store
          .dispatch("candidate/comment", {
            id: this.candidate.id,
            data: blankData,
          })
          .then((r) => {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: "Event Updated",
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.fetchCandidate();
            this.id = "";
            this.title = "";
            this.comment = "";
          })
          .catch((error) => {
            for (const [key, value] of Object.entries(
              error.response.data.errors
            )) {
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: "error",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                  text: value,
                },
              });
            }
          });
      } else {
        const Data = {
          id: this.id,
          title: this.title,
          comment: this.comment,
        };
        store
          .dispatch("candidate/commentEdit", {
            id: this.candidate.id,
            data: Data,
          })
          .then((r) => {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: "Event Updated",
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.fetchCandidate();
            this.id = "";
            this.title = "";
            this.comment = "";
          })
          .catch((error) => {
            for (const [key, value] of Object.entries(
              error.response.data.errors
            )) {
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: "error",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                  text: value,
                },
              });
            }
          });
      }
    },
    dateFormat(date) {
      return moment(String(date))
        ? moment(String(date)).format("DD/MM/YYYY HH:mm")
        : date;
    },
    truncate(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "&hellip;" : str;
    },
    textLimit(text) {
      if (Number(text.length) < 1001)
        this.characters = 1000 - Number(text.length);
      else this.characters = 0;
    },
    editComment(item) {
      this.id = item.id;
      this.title = item.title;
      this.comment = item.comment;
      this.isUpdate = true;
    },
    deleteComment(item) {
      store
        .dispatch("candidate/commentDelete", {
          id: item.id,
        })
        .then((response) => {
          this.title = "";
          this.comment = "";
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: "Comment Deleted",
              icon: "AlertTriangleIcon",
              variant: "success",
            },
          });
          this.fetchCandidate();
        })
        .catch((error) => {
          for (const [key, value] of Object.entries(
            error.response.data.errors
          )) {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: "error",
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: value,
              },
            });
          }
        });
    },
    CancelComment() {
      this.title = "";
      this.comment = "";
      this.isUpdate = false;
    },
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    candidate: {
      get() {
        return store.state.candidate.candidate;
      },
      set(value) {
        store.commit("UPDATE_CANDIDATE", value);
      },
    },
  },
};
</script>

<style>
</style>
